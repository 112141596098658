import { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Input, InputGroup, Button, FormMessage, Select } from '../../components/FormComponents';
import { Loading } from '../../components/Loading/Loading';
import { useMutation, useApolloClient } from "@apollo/client";
import { stateExternalStakeholder } from 'graphql/state';
import { loader } from "graphql.macro";
import styles from './CandidateSignUpScreen.module.scss';
import cn from 'classnames';
import PasswordStrengthBar from 'react-password-strength-bar';
import Cookies from 'js-cookie';
import { stateCompany, stateUser } from 'graphql/state';
import moment from 'moment';
import { useQuery } from '@apollo/client';

const CREATE_EXTERNAL_STAKEHOLDER = loader("./../../graphql/mutations/sign_up_external_stakeholder.graphql")
const COMPANIES_NAME_QUERY = loader("./../../graphql/queries/companies_name.graphql")

export const CandidateSignUpScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [scorePass, setScorePass] = useState(false);
  const [companies, setCompanies] = useState([])

  const { register, handleSubmit, watch, formState: { errors }, control } = useForm({
    reValidateMode: 'onChange',
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const client = useApolloClient();

  const [createCandidate, { loading: creatingCandidate }] = useMutation(CREATE_EXTERNAL_STAKEHOLDER, {
    onCompleted: async(data) => {
      if (data?.signUpExternalStakeholder) {
        const { token, externalStakeholder, company } = data.signUpExternalStakeholder;
        await client.clearStore();
        Cookies.set('token', token, { path: '' });
        setLoading(false);
        stateCompany(company);
        stateExternalStakeholder(externalStakeholder);
        navigate('/candidate_dashboard')
      } else {
        error("Password was not updated.");
      }
    },
    onError: (errorMessage) => {
      console.log("Error is ", errorMessage)
    },
  });

  const { loading: fetchingCompanies } = useQuery(COMPANIES_NAME_QUERY, {
    onCompleted: (data) => {
      if(data?.companiesName){
        setCompanies(data.companiesName)
      }
    }
  });

  const watchPassword = useWatch({ control, name: 'externalStakeholderAttributes.password'});

  useEffect(() => {
    if(!watchPassword) return;
    if (!scorePass)
      setError('The password is not strong enough')
    else
      setError(null);
  }, [watchPassword, scorePass])

  const onSubmit = (data) => {
    const date = `${moment(new Date()).format("DD/MM/YYYY")}`
    data.externalStakeholderAttributes['startDate'] = date
    data.externalStakeholderAttributes['email'] = data.externalStakeholderAttributes['email'].toLowerCase();
    createCandidate({variables:{ externalStakeholderAttributes: data.externalStakeholderAttributes}})
  }

  return (
    <div className={styles.root}>
      <Loading visible={creatingCandidate || fetchingCompanies} />
      <img className={styles.logoImg} src='/assets/images/knowmy/ensure.svg' alt='Ensure Logo' />
      <div className='card margin-top card-with-shadow'>
        <div className={cn(styles.content,'card_content')}>
          <h1 className='design-marker'>Sign Up For Growers!</h1>
          <FormMessage message={error} className='margin-bottom' onClose={() => setError(null)} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='inline'>
              <InputGroup title='First Name'>
                <Input
                  name='externalStakeholderAttributes.firstName'
                  placeholder='John'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.externalStakeholderAttributes?.firstName?.type}
                />
              </InputGroup>
              <InputGroup title='Last Name'>
                <Input
                  name='externalStakeholderAttributes.lastName'
                  placeholder='Doe'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.externalStakeholderAttributes?.lastName?.type}
                />
              </InputGroup>
            </div>
            <InputGroup title='Company Name'>
              <Select name='externalStakeholderAttributes.companyId' register={register} placeholder="Select Company">
                {companies?.map(company => (
                  <Select.Item key={`company-${company.id}`} value={company.id}>{company.name}</Select.Item>
                ))}
              </Select>
            </InputGroup>
            <InputGroup title='Email'>
              <Input
                name='externalStakeholderAttributes.email'
                placeholder='john@knowmy.io'
                register={register}
                validators={{ required: true }}
                error={errors?.externalStakeholderAttributes?.email?.type}
              />
            </InputGroup>
            <InputGroup title='Phone Number'>
              <Input
                name='externalStakeholderAttributes.phoneNumber'
                placeholder='john@knowmy.io'
                register={register}
                validators={{ required: true }}
                error={errors?.externalStakeholderAttributes?.email?.type}
              />
            </InputGroup>
            <InputGroup title='Password'>
              <Input
                name='externalStakeholderAttributes.password'
                type='password'
                placeholder='Enter password'
                register={register}
                validators={{ required: true }}
                error={errors?.externalStakeholderAttributes?.password?.type}
              />
              <PasswordStrengthBar
                password={watchPassword}
                onChangeScore={(score) => {score > 2 ? setScorePass(true) : setScorePass(false)}}
              />
            </InputGroup>
            <Button className='w-100' submit>Sign Up</Button>
            <Link className={cn('link-button', 'margin-top--small')} to="/sign_in">Already have a candidate account? Sign in</Link>
          </form>
        </div>
        <div className={cn('card_footer', styles.footer)}>
          <p>By clicking the button above, you agree to our <Link className='link' to='terms_of_service'>Terms of Service</Link> and <Link className='link' to='privacy_policy'>Privacy Policy</Link></p>
        </div>
      </div>
    </div>
  )
}
