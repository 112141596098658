import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout"
import { useState, useEffect } from "react";
import cn from "classnames";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import styles from './EnsureStakeholderEvidenceScreen.module.scss';
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { EvidenceList } from "./components/EvidenceList/EvidenceList";
import { Button, Select } from "components/FormComponents";
import { ModalForm } from "components/ModalForm/ModalForm";
import { EvidenceForm } from "./components/EvidenceForm/EvidenceForm";
import { useForm } from "react-hook-form";
import { Input } from "components/FormComponents";
import fuzzysort from "fuzzysort";
import { useWatch } from "react-hook-form";

const EVIDENCES_QUERY = loader("src/graphql/queries/external_stakeholder_evidences.graphql");
const HIDDEN_STAKEHOLDER_JOURNEY_QUERY = loader("src/graphql/queries/stakeholder_evidence_stakeholder_journey.graphql");
const CREATE_BLOCK_RESULT = loader("src/graphql/mutations/create_block_result.graphql");
const UPDATE_BLOCK_RESULT = loader("src/graphql/mutations/update_block_result_without_token.graphql");
const DELETE_BLOCK_RESULT = loader("src/graphql/mutations/delete_diary_block_result.graphql");
const UPDATE_EVIDENCE = loader("src/graphql/mutations/update_stakeholder_evidence.graphql")

export const EnsureStakeholderEvidenceScreen = () =>{

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm({
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    useDefaultValues: { tagType: ""},
    defaultValues: {
      tagType: ''
    }
  });

  const [evidences,setEvidences] = useState([])
  const [filteredEvidences , setFilteredEvidences] = useState([])
  const [hiddenJourney, setHiddenJourney] = useState(null)
  const [visible, setVisible] = useState(null)
  const [blockResult, setBlockResult] = useState(null)

  const {setLoading, alert} = useEnsureStakeholderLayoutContext();

  const tagWatch = useWatch({ control, name: "tagType" });

  const [fetchEvidences,{ loading: fetchingEvidences }] = useLazyQuery(EVIDENCES_QUERY, {
    onCompleted: data => {
      setEvidences(data?.externalStakeholderEvidences)
      setFilteredEvidences(data?.externalStakeholderEvidences)
    }
  });

  const { loading: fetchingHiddenJourney } = useQuery(HIDDEN_STAKEHOLDER_JOURNEY_QUERY, {
    onCompleted: data => {
      setHiddenJourney(data?.stakeholderEvidenceStakeholderJourney)
    }
  });

  const [ createBlockResult, { loading: creatingBlockResult } ] = useMutation(CREATE_BLOCK_RESULT, {
    onCompleted: async(data) => {
      setBlockResult(data?.createBlockResult?.id)
      setVisible(true)
    },
  });

  const [updateResult, { loading: updatingResult }] = useMutation(UPDATE_BLOCK_RESULT, {
    onCompleted: data => {
      const newEvidence = data?.updateBlockResultWithoutToken;
      setEvidences([...evidences, newEvidence])
      setFilteredEvidences([...filteredEvidences, newEvidence])
      setVisible(false)
      setBlockResult(null)
      alert("New evidence successfully added")
    }
  });

  const [deleteResult, { loading: deletingResult }] = useMutation(DELETE_BLOCK_RESULT, {
    onCompleted: data => {
      const deleted = data?.deleteDiaryBlockResult;
      setVisible(false)
      setBlockResult(null)
    }
  });

  const [updateEvidence, { loading: updatingEvidence }] = useMutation(UPDATE_EVIDENCE, {
    onCompleted: data => {
      const updated = data?.updateStakeholderEvidence;
      
      const updatedEvidences = evidences?.map((e) => e.id === updated.id ? updated : e);
      setEvidences(updatedEvidences);
      setFilteredEvidences(updatedEvidences);
      alert("Evidence successfully updated")
    }
  });

  const createEvidence = () =>{
    createBlockResult({variables: {stakeholderJourneyId: parseInt(hiddenJourney.id), blockId: parseInt(hiddenJourney?.journey?.blocks?.[0]?.id)}})
  }

  const allTags = evidences?.map(obj => obj.tags);

  const flattenedTags = allTags?.flat();

  const uniqueTags = flattenedTags?.reduce((acc, tag) => {
    if (!acc.find(t => t.id === tag.id)) {
      acc.push(tag);
    }
    return acc;
  }, []);

  const hiddenJourneyBlock = hiddenJourney?.journey?.blocks?.[0]

  useEffect(()=>{
    fetchEvidences()
  },[])

  useEffect(()=>{
    setLoading(fetchingEvidences || fetchingHiddenJourney || creatingBlockResult || updatingResult || deletingResult || updatingEvidence)
  },[fetchingEvidences, fetchingHiddenJourney, creatingBlockResult, updatingResult, deletingResult, updatingEvidence])

  const searchName = watch("searchName");

  useEffect(() => {
    if (searchName !== null && searchName?.trim() !== "") {
      const filter = fuzzysort.go(searchName, filteredEvidences, { key: "value.name", threshold: -10000 });
      const result = [];
      filter.forEach((e) => {
        result.push(e.obj);
      });
      setFilteredEvidences(result);
    } else {
      fetchEvidences();
    }
  }, [searchName]);

  useEffect(()=>{
    if(tagWatch !== 'empty'){
      const temp =  evidences?.map((evidence)=>{
        return evidence?.tags?.map((tag)=>{
          if (tag?.name === tagWatch){
            return evidence
          }
        })
      })
      const flatEvidences = temp.flat()
      const removedUndefined = flatEvidences?.filter((f)=>f)
      setFilteredEvidences(removedUndefined)
    }else{
      setFilteredEvidences(evidences)
    }
  },[tagWatch])

    return(
    <>
      <EnsureStakeholderLayout.Header>
        <EnsureStakeholderLayout.Title>Evidence</EnsureStakeholderLayout.Title>
        <EnsureStakeholderLayout.Subtitle>Below you can manage your records for spraying, fertiliser, spreading, etc</EnsureStakeholderLayout.Subtitle>
        <div>
          <div className={styles.header}>
            <Input name="searchName" register={register} type="text" icon="search" placeholder="Search for evidence" />
            <div className={styles.rightButtons}>
              <div className={styles.evidenceType}>
                <div className={styles.text}>Evidence Type</div>
                <Select className={styles.select} name='tagType' register={register}>
                  <Select.Item value="empty" active={tagWatch === ""}>
                    All Evidence
                  </Select.Item>
                  {uniqueTags?.map(type => (
                    <Select.Item key={type.id} value={type.name}>{type.name}</Select.Item>
                  ))}
                </Select>
              </div>
              <Button onClick={createEvidence} className={styles.button}>+ Add Evidence</Button>
            </div>
          </div>
        </div>
      </EnsureStakeholderLayout.Header>
      <EnsureStakeholderLayout.Body>
        <div className={cn(styles.root)}>
          <ModalForm visible={visible}>
            <EvidenceForm
             tags={uniqueTags}
             blockResultId={blockResult}
             fieldId={hiddenJourney?.journey?.blocks?.[0]?.fields?.[0]?.id}
             updateResult={updateResult}
             stakeholderJourneyId={hiddenJourney?.id}
             blockId={hiddenJourney?.journey?.blocks?.[0]?.id}
             deleteResult={deleteResult}
             setVisible={setVisible}
             />
          </ModalForm>
          {
            filteredEvidences?.map((evidence, index)=>{
              return(
                <EvidenceList
                key={index}
                blockName={ hiddenJourneyBlock?.id === evidence?.stakeholderJourneyBlockResult?.block?.id ?
                  'Directly added evidence' : evidence?.stakeholderJourneyBlockResult?.block?.name}
                {...evidence?.value}
                createdAt={evidence?.createdAt}
                tags={evidence?.tags}
                upload={evidence?.upload}
                value={evidence?.value}
                allTags={uniqueTags}
                completeEvidence={evidence}
                blockResultId={evidence?.stakeholderJourneyBlockResult?.id}
                updateEvidence={updateEvidence}
                />
              )
            })
          }
        </div>
      </EnsureStakeholderLayout.Body>
    </>
    )
}