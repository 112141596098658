
import { Badge } from "react-bootstrap";
import { IconButton } from "components/IconButton/IconButton";
import styles from './DiaryEntries.module.scss';
import cn from 'classnames';
import { CollapsibleBlock } from "components/CollapsibleBlock/CollapsibleBlock";
import moment from "moment";
import { QuestionnaireBlockResult } from "views/StakeholderProfileScreen/tabs/Journeys/components/QuestionnaireBlockResult/QuestionnaireBlockResult";
import { Button } from "components/FormComponents";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { useState } from "react";

export const DiaryEntries = ({deleteEntry, downloadResult, block, blockResults}) => {

    const [openModal, setOpenModal] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [blockResultsDisplay , setBlockResultsDisplay] = useState(blockResults)

    const deleteDiaryEntry=(id)=>{
        deleteEntry({variables:{id: id}})
        const filtered = blockResultsDisplay?.filter((bR)=>bR.id !== id)
        setBlockResultsDisplay(filtered)
    }

    const downloadBlockResults=()=>{
        downloadResult({variables: {blockResultIds: blockResultsDisplay?.map((bR)=>bR.id)}})
    }
    
    return(
        <div className={cn('card', 'card-with-border', styles.root)}>
             <ConfirmationModal
                visible={openModal}
                title={`Delete Entry`}
                subtitle={`Diary Entry will be permanently deleted. This data cannot be recovered`}
                confirmationIcon="trash-2"
                onClose={() => {
                setDeleteId(null)
                setOpenModal(false)
                }}
                onConfirmation={()=>{
                    deleteDiaryEntry(deleteId)
                    setOpenModal(false)
                }}
                buttonTitle="Delete Entry"
            />
            <div className={cn(styles.content, 'card_content w-100')}>
                <header className='w-100 d-flex justify-content-between'>
                    <div>
                    <div className='d-flex justify-content-between'>
                        <Badge bg="" className={cn(styles.status)}>In-Progress</Badge>
                    </div>
                    <h1 className='title-2 margin-top'>{block.name}</h1>
                    <p className={styles.entries}>{blockResultsDisplay?.length} entries</p>
                    </div>
                    <Button onClick={downloadBlockResults} className={styles.downloadButton} type="secondary" icon="download">
                        Download
                    </Button>
                </header>
            </div>
            <div className={cn('card_content', 'background-secondary', styles.content)}>
                {blockResultsDisplay?.map((blockResult, index)=>{
                    const createdAt = blockResult?.createdAt
                    const updatedAt = blockResult?.updatedAt
                    
                    const parsedTime = moment(createdAt);
                    const formattedTime = parsedTime.format("DD MMMM, YYYY");

                    return(
                        <CollapsibleBlock key={blockResult?.id} color={"#134f5c"} className={cn(styles.row, 'margin-bottom')}>
                            <CollapsibleBlock.Header>
                                <header className={styles.header}>
                                    <div className={styles.title}>
                                        <p className='title-4 font-weight-500'>Diary Entry #{index+1}</p>
                                        {createdAt !== updatedAt && <p>Last updated {formattedTime}</p>}                  
                                    </div>
                                </header>
                                <div className={styles.iconsDiv}>
                                    <IconButton onClick={()=>{
                                        setDeleteId(blockResult.id)
                                        setOpenModal(true)
                                    }} className={styles.toggleButton} icon='trash-2'/>
                                </div>
                            </CollapsibleBlock.Header>
                            <CollapsibleBlock.Content className={cn(styles.blockResult)}>
                                <QuestionnaireBlockResult block={block} blockResult={blockResult} />
                            </CollapsibleBlock.Content>
                        </CollapsibleBlock>
                    )
                })}
             </div>                
        </div>
    )
}