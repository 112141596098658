import { CollapsibleBlock } from "components/CollapsibleBlock/CollapsibleBlock";
import styles from './EvidenceList.module.scss';
import cn from 'classnames';
import moment from "moment";
import { IconButton } from "components/IconButton/IconButton";
import { useState } from "react";
import { ModalForm } from "components/ModalForm/ModalForm";
import { EvidenceUpdateForm } from "./components/EvidenceUpdateForm/EvidenceUpdateForm";

export const EvidenceList = ({updateEvidence, blockResultId, completeEvidence, allTags, value, upload, tags, key, createdAt, blockName, name}) =>{
    const [visible, setVisible] = useState(null)

    const formatUpdatedAt=(dateString)=> {
        return `Added ${moment.utc(dateString).local().format('DD/MM/YYYY @ h:mma')}`;
    }
    
    return(
        <>
          <ModalForm visible={visible}>
            <EvidenceUpdateForm
              value={value}
              tags={tags}
              setVisible={setVisible}
              allTags={allTags}
              evidence={completeEvidence}
              blockResultId={blockResultId}
              updateEvidence={updateEvidence}
            />
          </ModalForm>
          <CollapsibleBlock actionComponent={<IconButton icon="edit-2" className={styles.iconButton} onClick={()=>{setVisible(true)}}/>} color='#1f4f5c' headerClassName={tags?.length ? styles.headerClassName : styles.taglessHeaderClassName} className={cn(styles.root, 'margin-bottom')}  key={key}>
                <CollapsibleBlock.Header>
                <header className={styles.header}>
                    <div className={styles.title}>
                      <p className={cn('title-4', styles.blockName)}>{blockName}</p>  
                      <p className={styles.name}>{name || 'Evidence name not provided'}</p>
                    </div>
                    <div className={styles.updatedAt}>{formatUpdatedAt(createdAt)}</div>
                    <div className={styles.tags}>{
                      tags?.length ? tags?.map((tag)=>{
                        return(
                          <div className={styles.pill}>
                            <div className={styles.text}>
                              {tag.name}
                            </div>
                          </div>
                        )
                      })
                      : <div>No Tags Associated</div>
                    }</div>
                  </header>
                </CollapsibleBlock.Header>
                <CollapsibleBlock.Content>
                  <div className={styles.content}>
                    <div className={styles.tile}>
                      <a href={upload?.url} className={cn(styles.link,"link")} target="_blank">
                        {upload?.filename}
                      </a>
                    </div>
                  </div>
                </CollapsibleBlock.Content>
          </CollapsibleBlock>
        </>
    )
}