import styles from './Sidebar.module.scss';
import { gapi } from 'gapi-script';
import { Link, NavLink } from 'react-router-dom';
import { Fragment, useEffect, useState, useRef } from 'react';
import FeatherIcon from 'feather-icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { stateExternalStakeholder } from './../../../../graphql/state';
import { BiTachometer,BiListCheck, BiFileBlank, BiFile, BiDetail} from 'react-icons/bi';
import cn from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { Loading } from 'components/Loading/Loading';
import { useClickOutside } from 'hooks/click-outside';

export const Sidebar = ({
  width,
  onStateChange = () => {}
}) => {

  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const mobileView = width <= 768;
  const wrapperRef = useRef(null);

  const hideBar=()=>{
    if(mobileView){
      setHidden(true)
    }
  }

  useClickOutside(wrapperRef, hideBar);
  

  const logout = async () => {
    if (gapi.auth2) {
      try {
        const auth2 = gapi.auth2.getAuthInstance();
        await auth2.signOut();
      } catch {
        console.log('No auth2 signOut method')
      }
    }
    Cookies.remove('token', { path: '' });
    stateExternalStakeholder(null);
  }

  const stakeholder = useReactiveVar(stateExternalStakeholder)
  const firstLetter = stakeholder?.name.split('')[0];
  const firstName = stakeholder?.name.split(' ')[0]

  const NavLinks = [
    {
      title: 'Dashboard',
      path: '/ensure_dashboard',
      icon: <BiTachometer  fontSize={25} />,
    },
    {
      title: 'My Diaries',
      path: '/my_diaries',
      icon: <BiListCheck  fontSize={25} />,
    },
    {
      title: 'Evidence',
      path: '/evidence',
      icon: <BiFileBlank color="#134f5c" fontSize={25} />,
    },
    {
      title: 'Guidebook',
      path: '/not_set',
      icon: <BiFile color="#134f5c" fontSize={25} />,
      disabled: true,
    },
    {
      title: 'Records',
      path: '/not_set',
      icon: <BiDetail color="#134f5c" fontSize={25} />,
      disabled: true,
    },
  ]


  useEffect(() => onStateChange(hidden), [hidden]);

  // Disabled links
  const checkDisabled = (e, link) => { if(link.disabled) e.preventDefault() }

  if (loading) return <Loading visible={loading} />;


  const displaySideBar = (link)=>(
      <Fragment key={`fragment-${link.path}`}>
        <OverlayTrigger
          key={`overlay-${link.path}`}
          trigger={hidden ? ['hover', 'focus'] : null}
          placement='right'
          overlay={<Tooltip>{link.title}</Tooltip>}
        >
          <NavLink
            onClick={e => checkDisabled(e, link)}
            key={`link-${link.path}`}
            to={link.path}
            className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive, [styles.linkDisabled]: link.disabled })}
          >
            {link.icon}
            <div className={styles.title}>{link.title}</div>
          </NavLink>
        </OverlayTrigger>
      </Fragment>
    )

  return (
    <aside ref={wrapperRef} className={cn(styles.root, { [styles.rootHidden]: hidden })}>
      <div className={cn(styles.header)} >
        <Link className={cn(styles.headerLink, 'margin-top--large margin-bottom', { [styles.headerLinkHidden]: hidden })} to='/ensure_dashboard' >
          <img src='/assets/images/knowmy/ensure.png' alt='Ensure Logo' className={styles.logo} />
        </Link>
       { !!mobileView && <FeatherIcon className={cn(styles.icon, {[styles.iconHidden]: !hidden})} icon='align-justify' onClick={() => setHidden(!hidden)}/>}
      </div>
      <div className={cn(styles.content, { [styles.contentHidden]: hidden })}>
        <div className={styles.menu}>
          {!!mobileView && <FeatherIcon className={styles.icon} icon='align-justify' onClick={() => setHidden(!hidden)} /> }
          {NavLinks.map(link => (
            displaySideBar(link)
          ))}
        </div>
        <div className={styles.footer} >
          <OverlayTrigger trigger={hidden ? ['hover', 'focus'] : null} placement='right' overlay={<Tooltip>{stakeholder?.name}</Tooltip>}>
            <NavLink
              to='/ensure_candidate_account'
              className={({ isActive }) => cn(styles.link,{ [styles.linkActive]: isActive })}
            >
              <div className={cn(styles.img, 'title-3')}>{firstLetter}</div>
              <span className={styles.title}>{firstName}</span>
            </NavLink>
          </OverlayTrigger>
          <OverlayTrigger trigger={hidden ? ['hover', 'focus'] : null}  placement='right' overlay={<Tooltip>Log out</Tooltip>}>
            <NavLink className={cn( styles.link)} onClick={logout}>
              <div><FeatherIcon icon='log-out' /></div>
              <span className={styles.title}>Log Out</span>
            </NavLink>
          </OverlayTrigger>
        </div>
      </div>
      <FeatherIcon icon='chevron-left' className={styles.toggleButton} onClick={() => setHidden(!hidden)} />
    </aside>
  )
}
