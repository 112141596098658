import { List } from "components/List/List";
import { useState, useEffect } from "react";
import { DiaryEntries } from "./components/DiaryEntries/DiaryEntries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { saveAs } from "file-saver";
import { consolidatedCsvs } from "utils/helpers";

const DELETE_DIARY_ENTRY_MUTATION = loader("src/graphql/mutations/delete_block_result.graphql")
const DOWNLOAD_BLOCK_RESULT_QUERY = loader("src/graphql/queries/multiple_block_results.graphql")

export const Diaries = ({alert, setLoading, triggerUnexpectedError, stakeholder}) => {

    const [block, setBlock] = useState(null)
    const [blockResults , setBlockResults] = useState([])

    const [ deleteEntry, { loading: deletingEntry } ] = useMutation(DELETE_DIARY_ENTRY_MUTATION, {
        onCompleted: async(data) => {
            const deletedResult = data.deleteBlockResult;
            const filtered = blockResults?.filter((result)=>result.id !== deletedResult.id)
            setBlockResults(filtered)
            alert("Diary Entry Deleted Successfully")
        },
        onError: error => triggerUnexpectedError(error.message)
    });

    const [ downloadResult, { loading: downloading } ] = useLazyQuery(DOWNLOAD_BLOCK_RESULT_QUERY, {
        onCompleted: async(data) => {
            const blob = consolidatedCsvs(data.multipleBlockResults.map((csv)=>csv.result))
            saveAs(
                blob,
                `${block?.name.replace(/ /g, "_")}.csv`
            );
        },
        onError: error => triggerUnexpectedError(error.message)
      });

    useEffect(()=>{
        setLoading(deletingEntry || downloading)
    },[deletingEntry, downloading])

    const filteredBlockResults = stakeholder?.stakeholderJourneys.map(stakeholderJourney => 
        stakeholderJourney.blockResults.filter(blockResult => 
        blockResult.block.typeOf === 'diary'
        )).flat();

    const uniqueBlocksMap = new Map();
    stakeholder?.stakeholderJourneys?.forEach(sj=>{
        sj?.journey?.journeyBlocks?.forEach(jB=>{
            if(jB.block.typeOf==='diary'){
                uniqueBlocksMap.set(jB.block.id, jB.block)
            }
        })
    })
   
    const uniqueBlocks = Array.from(uniqueBlocksMap.values());

    return(
        <div className="d-flex">
            <List
                title="Diaries"
                subtitle={`Select one of the Diaries to view all existing entries.`}
                className="margin-right--large"
            >
                <List.Category title={`${stakeholder?.name}'s Diaries`} isTitleLarge>
                    {uniqueBlocks?.map((block) => (
                        <List.Item
                         key={block.id}
                         title={block.name}
                         value={block.id}
                         onClick={() => {
                            setBlock(block)
                            const blockResults = filteredBlockResults?.filter((result)=>result.block.id === block.id)
                            setBlockResults(blockResults)
                        }}
                        />
                     ))
                    }
                </List.Category>
            </List>

            {block && 
                <DiaryEntries downloadResult={downloadResult} deleteEntry={deleteEntry} block={block} blockResults={blockResults}/>
            }
        </div>
    )
}