// @ts-nocheck
import { useForm } from 'react-hook-form';
import { Input, InputGroup, Button } from './../../components/FormComponents';
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useState } from 'react';
import { Loading } from './../../components/Loading/Loading';
import styles from './GrowerForgetPasswordScreen.module.scss';
import img from '../../assets/images/emailImage.svg';
import { GrowerForgetPasswordFormData } from './interfaces/GrowerForgetPasswordFormData';

const SEND_RECOVERY_MAIL_MUTATION = loader('src/graphql/mutations/send_grower_password_recovery_mail.graphql');


export const GrowerForgetPasswordScreen = () => {
  const [error, setError] = useState<string | undefined>();
  const [sent, setSent] = useState(false);

  const { register, handleSubmit } = useForm<GrowerForgotPasswordFormData>({
    reValidateMode: 'onChange',
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const [ sendRecoveryMail, { loading: sendingRecoveryMail } ] = useMutation(SEND_RECOVERY_MAIL_MUTATION, {
    onCompleted: async(data) => {
      if (data.sendGrowerPasswordRecoveryMail) {
        setSent(true)
      }
    },
    onError: error => {
      setError(error.message)
    }
  });

  const onSubmit = (data: GrowerForgotPasswordFormData) => {
    sendRecoveryMail({
      variables: {
        email: data.email
      }
    })
  }

  return (
    <div className={styles.mainContainer}>
      <Loading visible={sendingRecoveryMail} />
      <img className={styles.logoImg} src='/assets/images/knowmy/ensure.svg' alt='KnowMy Logo' />
      <div className='card card-with-shadow margin-top'>
        <div className={cn(styles.contentContainer, 'card_content')}>
          {!sent && (
            <>
              <h1 className={cn(styles.primaryText, 'design-marker')}>Forgot your password?</h1>
              <p className={styles.secondaryText}>Enter your <span> grower </span> email address to reset your password. You may need to check your spam folder.</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup title='Email'>
                <Input
                  name='email'
                  icon='mail'
                  placeholder='Enter your email'
                  register={register}
                  validators={{ required: true }}
                  error={error}
                  inputProps={{ tabIndex: 1 }}
                  className={styles.input}
                />
                </InputGroup>
                <Button submit buttonProps={{ tabIndex: 3 }} className='w-100'>Reset Password</Button>
                <Link className={cn('link-button', 'margin-top--small')} to="/sign_in">Return to Sign In</Link>
              </form>
            </>
          )}
          {sent && (
            <>
              <h1 className={cn(styles.primaryText, styles.primaryAdjusted, 'design-marker')}>Password reset link sent</h1>
              <p className={cn(styles.secondaryText, styles.secondaryAdjusted)}>You should receive an email with a link to reset your password. You can close this window.</p>
              <img className={styles.emailImg} src={img} alt='Email sent' />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
