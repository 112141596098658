import { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { stateCompany, stateExternalStakeholder, stateUser } from './graphql/state';
import { loader } from "graphql.macro";
import { useQuery, useReactiveVar } from "@apollo/client";
import Cookies from 'js-cookie';
import { Loading } from './components/Loading/Loading';
import { AppLayout } from './templates/AppLayout/AppLayout';
import { StakeholderJourneyLayout } from './templates/StakeholderJourneyLayout/StakeholderJourneyLayout';
import {
  SignInScreen,
  SignUpScreen,
  AuthorizationScreen,
  KnowMyIntegrationScreen,
  DashboardScreen,
  BlockBuilderScreen,
  AccountScreen,
  NotFoundScreen,
  StakeholdersScreen,
  NewStakeholderJourneyScreen,
  StakeholderProfileScreen,
  StakeholderJourneyWelcomeScreen,
  StakeholderJourneyGetStartedScreen,
  StakeholderJourneyBlockScreen,
  StakeholderJourneyOptionalPdfScreen,
  StakeholderJourneyBlockResultDownload,
  ForgotPasswordScreen,
  ResetPasswordScreen,
  EmailVerifiedScreen,
  CompanySettingsScreen,
  AddStakeholderToJourneyScreen,
  PublicJourneyInvitationScreen,
  ApiDocumentation,
  OAuthCallbacks,
  CandidateSignUpScreen,
  CandidateSignInScreen,
  ExternalStakeholderDashboardScreen,
  ExternalStakeholderAccountScreen,
  EnsureStakeholderDashboardScreen,
  EnsureStakeholderAccountScreen,
  EnsureStakeholderTasksListScreen,
  EnsureStakeholderEvidenceScreen,
  SignEasyTemplateConfigured,
  LaunchScreen,
  GrowerForgetPasswordScreen,
  GrowerResetPasswordScreen
} from './views';
import { useQueryParams } from 'hooks/query-params';
import { VerifyEmailScreen } from 'views/VerifyEmailScreen/VerifyEmailScreen';
import { DocumentScreen } from 'views/DocumentsScreen/DocumentScreen';
import { Buffer } from 'buffer';
import { AdminScreen } from 'views/AdminScreen/AdminScreen';
import { Provider, ErrorBoundary } from '@rollbar/react';
import jwt from 'jwt-decode';
import { checkPermission } from 'utils/helpers';
import { PublicJourneyLayout } from 'templates/PublicJourneyLayout/PublicJourneyLayout';
import { KnowMyIntegrationLayout } from 'templates/KnowMyIntegrationLayout/KnowMyIntegrationLayout';
import { ExternalStakeholderLayout } from 'templates/ExternalStakeholderLayout/ExternalStakeholderLayout';
import { EnsureStakeholderLayout } from 'templates/EnsureStakeholderLayout/EnsureStakeholderLayout';

const ME_QUERY = loader("./graphql/queries/me.graphql");
const STAKEHOLDER_QUERY = loader("./graphql/queries/logged_in_external_stakeholder.graphql")

export const App = () => {

  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    environment: process.env.REACT_APP_ENV,
  };

  const [loading, setLoading] = useState(true);

  useQuery(ME_QUERY, {
    onCompleted: data => {
      stateUser(data.me);
      stateCompany(data.me?.company)
      setLoading(false);
    },
    onError: error => { setLoading(false) }
  });

  useQuery(STAKEHOLDER_QUERY, {
    onCompleted: data => {
      stateExternalStakeholder(data.loggedInExternalStakeholder);
    },
    onError: error => { setLoading(false) }
  });

  if(loading) return <Loading visible={loading} />

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Routes>
          <Route path='/signeasy_template_configured' element={<SignEasyTemplateConfigured/>}/>
          <Route path="/sign_in" element={<LoginRoute><SignInScreen /></LoginRoute>}/>
          {/* <Route path="/sign_up" element={<LoginRoute><SignUpScreen /></LoginRoute>}/> */}
          <Route exact path="/" element={<LoginRoute><SignInScreen /></LoginRoute>}/>
          <Route path="/candidate_sign_up" element={<LoginStakeholder><CandidateSignUpScreen/></LoginStakeholder>}/>
          <Route path="/candidate_sign_in" element={<LoginStakeholder><CandidateSignInScreen/></LoginStakeholder>}/>
          <Route path="/forgot_password" element={<ForgotPasswordScreen></ForgotPasswordScreen>}/>
          <Route path="/:signedId/reset_password" element={<ResetPasswordScreen resetPasswordType></ResetPasswordScreen>} />
          <Route path="/grower_forgot_password" element={<GrowerForgetPasswordScreen></GrowerForgetPasswordScreen>}/>
          <Route path="/:signedId/grower_reset_password" element={<GrowerResetPasswordScreen resetPasswordType></GrowerResetPasswordScreen>} />
          <Route path="/:signedId/invitation_email"
            element={<ResetPasswordScreen></ResetPasswordScreen>} />
          <Route path="/confirm_email/:signedId" element={<EmailVerifiedScreen></EmailVerifiedScreen>}/>
          <Route path="/email_verification/" element={<VerifyRoute><VerifyEmailScreen /></VerifyRoute>}/>
          <Route path="/authorization" element={<AuthorizationScreen />} />
          <Route path="/integrations/:callback_name" element={<OAuthCallbacks />} />
          <Route element={<KnowMyIntegrationLayout />}>
            <Route path="/integration" element={<KnowMyIntegrationScreen />} />
          </Route>

          <Route element={<AppLayout />}>
            <Route path="/dashboard" element={<PrivateRoute permission="journey"><DashboardScreen /></PrivateRoute>}/>
            <Route path="/block_builder" element={<PrivateRoute permission="block"><BlockBuilderScreen /></PrivateRoute>} />
            <Route path="/company_settings" element={<PrivateRoute permission="user"><CompanySettingsScreen /></PrivateRoute>}/>
            <Route path="/account" element={<PrivateRoute permission="profile"><AccountScreen /></PrivateRoute>}/>
            <Route path="/stakeholders" element={<PrivateRoute permission="stakeholder_tag"><StakeholdersScreen /></PrivateRoute>} />
            <Route path="/new_journey" element={<PrivateRoute permission="journey"><NewStakeholderJourneyScreen /></PrivateRoute>} />
            <Route path="/new_launch" element={<PrivateRoute permission="journey"><LaunchScreen /></PrivateRoute>} />
            <Route path="/profile/:external_stakeholder_id"
              element={<PrivateRoute permission="external_stakeholder"><StakeholderProfileScreen /></PrivateRoute>}
              />
            <Route path="/documents" element={<PrivateRoute permission="report"><DocumentScreen /></PrivateRoute>} />
            <Route path="/admin" element={<PrivateRoute permission="impersonate"><AdminScreen /></PrivateRoute>} />
            <Route exact path="/" element={<PrivateRoute permission="journey"><DashboardScreen /></PrivateRoute>}/>
          </Route>

          <Route element={<StakeholderJourneyLayout />} >
            <Route path="/journey/:token/welcome" element={<StakeholderJourneyWelcomeScreen />} />
            <Route path="/journey/:token/get_started" element={<StakeholderJourneyGetStartedScreen />}  />
            <Route path="/journey/:token/block/:journey_block_id" element={<StakeholderJourneyBlockScreen />} />
            <Route path="/journey/:token/optional_pdf" element={<StakeholderJourneyOptionalPdfScreen />} />
            <Route path="/journey/:token/download/:block_result_id" element={<StakeholderJourneyBlockResultDownload/>} />
          </Route>

          <Route element={<PublicJourneyLayout />} >
            <Route path="/journey/:journeyId" element={<AddStakeholderToJourneyScreen />} />
            <Route path="/journey_invitation/:journeyId" element={<PublicJourneyInvitationScreen />} />
          </Route>

          <Route element={<ExternalStakeholderLayout/>} >
            <Route path="/candidate_dashboard" element={<ExternalStakeholderRoute ><ExternalStakeholderDashboardScreen /></ExternalStakeholderRoute>}/>
            <Route path="/candidate_account" element={<ExternalStakeholderRoute ><ExternalStakeholderAccountScreen /></ExternalStakeholderRoute>}/>
          </Route>

          <Route element={<EnsureStakeholderLayout/>} >
            <Route path="/ensure_dashboard" element={<ExternalStakeholderRoute ><EnsureStakeholderDashboardScreen /></ExternalStakeholderRoute>}/>
            <Route path="/ensure_candidate_account" element={<ExternalStakeholderRoute ><EnsureStakeholderAccountScreen /></ExternalStakeholderRoute>}/>
            <Route path='/my_diaries' element={<ExternalStakeholderRoute ><EnsureStakeholderTasksListScreen /></ExternalStakeholderRoute>}/>
            <Route path='/evidence' element={<ExternalStakeholderRoute ><EnsureStakeholderEvidenceScreen /></ExternalStakeholderRoute>}/>
          </Route>

          <Route path="/api_docs" element={<ApiDocumentation />}/>

          <Route path='*' element={<NotFoundScreen />} />

        </Routes>
      </ErrorBoundary>
    </Provider>
  );
}

const VerifyRoute = ({ children }) => {
  const user = useReactiveVar(stateUser);
  if(user) {
    return user.verified ? <Navigate to='/sign_in' /> : children
  } else {
    return <Navigate to='/sign_in' />
  }
}

const ExternalStakeholderRoute = ({children}) => {
  useQuery(STAKEHOLDER_QUERY, {
    onCompleted: data => {
      data?.loggedInExternalStakeholder?.stakeholderJourneys.forEach(stakeholderJourney => {
        stakeholderJourney.stakeholderBlocks.forEach(stakeholderBlock => {
            // Check if typeOf is not "diary"
            if (stakeholderBlock.block.typeOf !== "diary") {
                // If typeOf is not "diary", convert result to object at 0th index
                stakeholderBlock.result = stakeholderBlock.result[0];
            }
        });
    });
      stateExternalStakeholder(data?.loggedInExternalStakeholder);
    }
  });
  const stakeholder = useReactiveVar(stateExternalStakeholder);
  if (stakeholder){
    return children;
  }else{
    return <Navigate to='/candidate_sign_in' />
  }
}

const PrivateRoute = ({ permission, children }) => {
  const impersonatorId = Cookies.get('token') ? jwt(Cookies.get('token'))?.impersonator_id : null
  const user = useReactiveVar(stateUser);
  if(checkPermission(user, `read:${permission}`) || impersonatorId) {
    return user.verified ? children : impersonatorId ? children : <Navigate to='/email_verification' />
  } else {
    return <Navigate to='/sign_in' />
  }
}

const LoginStakeholder = ({children}) => {
  const stakeholder = useReactiveVar(stateExternalStakeholder);
  if(stakeholder){
    return <Navigate to='/ensure_dashboard' />
  }else{
    return children;
  }
}

const LoginRoute = ({ children }) => {
  const user = useReactiveVar(stateUser);
  const [ redirectUri, state ] = useQueryParams(['redirect_uri', 'state']);
  const impersonatorId = Cookies.get('token') ? jwt(Cookies.get('token'))?.impersonator_id : null

  if(user) {
    if(redirectUri)   {
      const queryParams = Buffer.from(state, 'base64').toString();
      return <Navigate to={`/${redirectUri}${queryParams}`} />
    }
    else if(user.verified || impersonatorId ) {
      return <Navigate to='/dashboard' />
    }
    else {
      return <Navigate to='/email_verification' />
    }
  } else {
    return children
  }
}
