import { Input, InputGroup } from "components/FormComponents";
import cn from 'classnames';
import styles from './EvidenceUpdateForm.module.scss';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { Button } from "components/FormComponents";
import { FilePicker, MultipleChoice } from "components/FormComponents";
import { ReactCretable } from "components/FormComponents/ReactCreatable/ReactCreatable";
import { useEffect } from "react";

export const EvidenceUpdateForm=({updateEvidence, blockResultId,evidence, allTags, value, tags, setVisible, blockId, stakeholderJourneyId})=>{

    const {watch, register, reset,control, handleSubmit, clearErrors , unregister, setError, formState: { errors }, } = useForm();
    const { remove, fields: answers } = useFieldArray({ control, name: "answersAttributes" });

    const defaultAnswerTags = tags?.map(tag => { return { label: tag.name, value: tag.id} })

    useEffect(() => {
        let attributes = {
          id: blockResultId,
          answersAttributes: [],
        };
        
        attributes.answersAttributes.push({value: value})

        attributes.answersAttributes[0].tags = tags
        attributes.answersAttributes[0].fieldId = evidence?.field?.id
        attributes.answersAttributes[0].id = evidence.id
  
        reset(attributes);
      }, []);

    const transformAnswerTags =(tagsAttributes)=>{
    
        const prevAnswerTags = tags
    
        const attributes =  tagsAttributes?.map(tagAttr => {
          // Check if the object has the __isNew__ property
          if (tagAttr.__isNew__) {
            // If it's a new tag, remove label, value, and __isNew__ properties
            const { label, value, ...rest } = tagAttr;
            // Add name key with the value of label
            return { name: label};
          } else {
            // If it's an existing tag, remove label and value properties
            const { label, value} = tagAttr;
    
            return { id: value }
          }
        });
    
        const newAttIdNames = attributes?.map((a)=> a?.id)
    
        const changedAttributes = prevAnswerTags?.map((prevATag)=>{
          if(!newAttIdNames?.includes(prevATag?.id)){
            attributes.push({id: prevATag.id, _destroy: true})
          }
        })
        
        return attributes?.filter((f)=>f)
      }
    const onSubmit = (data) =>{
        const transformmedTags= transformAnswerTags(data?.answersAttributes[0]?.tagsAttributes);
        data.answersAttributes[0].tagsAttributes = transformmedTags;
        delete data.answersAttributes[0].tags;
        delete data.answersAttributes[0].value.__typename;

        updateEvidence({variables: {blockResultAttributes: {...data}}})
        onCancel()
    }   

    const onCancel=()=>{
        setVisible(false)
        reset()
    }

    return(
    <form className={cn('card', styles.root)} onSubmit={handleSubmit(onSubmit)}>
        <div className='card_content'>
          <h1 className={cn(styles.title, 'title-3 mb-5')}>Edit profile</h1>
            <InputGroup title={"Name the Evidence"}>
                <Input
                    name={`answersAttributes.0.value.name`}
                    register={register}
                    placeholder="enter your information here"
                />
            </InputGroup>
            <InputGroup title="Tag the evidence type">
                <Controller
                    name={`answersAttributes.0.tagsAttributes`}
                    defaultValue={defaultAnswerTags}
                    control={control}
                    render={({ field }) => (
                        <ReactCretable
                        field={field}
                        placeholder="Enter a new tag or select from a list of existing tags..."
                        options={allTags?.map(tag =>
                            { return { label: tag.name, value: tag.id} })}
                        isMulti={true}
                        />
                    )}
                />
            </InputGroup>
            <InputGroup title="Upload required evidence">
                <Controller
                    control={control}
                    name={`answersAttributes.0.value.uploadId`}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                        <>
                        <FilePicker
                            value={value}
                            onChange={onChange}
                            error={errors?.answersAttributes?.[0]?.value?.uploadId?.type}
                        />
                        </>
                    )}
                />
            </InputGroup>        
            <InputGroup title="Please provide details">
                <Input
                    name={`answersAttributes.0.value.text`}
                    register={register}
                    placeholder="Enter details here"
                />
            </InputGroup>                                      
        </div>
        <div className={cn(styles.noBorder, 'card_footer', 'd-flex', 'justify-content-between')}>
          <Button type='secondary' onClick={onCancel} >Cancel</Button>
          <Button submit>Save</Button>
        </div>
    </form>
    )
}